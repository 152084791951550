/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */


//tela maquinas

.card-maq {
  background-color: #ffffff;
  border: solid 1px #e3e3e3;
}

.text-nome-maq {
  padding: 5px;
  padding-left: 10px;
}

.desc-sec {
  padding: 5px;

  .text-desc-t {
    font-weight: 700;
    color: black;
    font-size: 10px;
  }

  .text-desc-f {
    font-size: 12px;
    color: black;
  }
}

.botao-estilo-equips {
  margin-top: 3px;
  padding-bottom: 6px !important;
  padding-top: 6px;
  font-size: 12px !important;
}

//tela maquina detalhe

.card-item-maq {
  cursor: pointer;
  transition: 0.3s;
  border: solid 1px #d9d9d9;
  background-color: #ffffff;
  border-radius: 5px;
}

.card-item-maq:hover {
  transition: 0.3s;
  border: solid 1px #d9d9d9;
  background-color: #eaeaea;
  border-radius: 5px;
}

.pad-card {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.titulo-card-maq {
  font-weight: 600;
  font-size: 13px;
  color: black;
}

.topo-tabela {
  background-color: #a21212;
  color: #ffffff;
}

tr {
  border: solid 1px #c2c2c2;
}

td {
  padding: 5px;
}

.tabela-topo {
  border-bottom: 2px solid #939393 !important;
  font-weight: 500;
  padding: 5px;
  border: solid 1px #d0d0d0;
}

.tabela-geral {
  border-top: none !important;
  font-size: 13px;
  padding: 5px;
  border: solid 1px #d0d0d0;
}

.btn-rep-video {
  font-size: 12px !important;
  padding: 5px;
  background-color: #fec820 !important;
  margin: 2px;
  color: black !important;
}

.btn-rep-visualizar {
  font-size: 12px !important;
  padding: 5px;
  background-color: #16b975 !important;
  border: #a21212 !important;
  margin: 2px;
  color: black !important;
}

.btn-rep-video-ext {
  padding: 13px;
  min-width: 130px;
  background-color: #fec820 !important;
  color: #000 !important;
}

.estilo-btn-back {
  box-shadow: none !important;
  border: solid 1px #c7c7c7 !important;
  background-color: transparent !important;
}

.title-content {
  font-size: 14px;
  font-weight: 600;
  color: black;
}

.nome-maq {
  font-size: 15px;
  color: black;
  font-weight: 500;
}

.card-sem-video {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border: solid 1px #c4c4c4;
  border-top: none;
  padding: 10px;
}

.card-termo {
  border: solid 1px #dcdcdc;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 6px;
}

.download-termo {
  font-size: 12px !important;
  padding: 15px;
  border: none !important;
  background-color: #fec820 !important;
  color: black !important;
}

.text-nome-garantia {
  font-weight: 500;
  color: black;
}


//pagina empresa

.qtd-maq {
  font-size: 17px;
  color: black;
  font-weight: 600;
}

.not-found {
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  color: black;
}

.estilo-card-inicio:hover {
  color: white !important;
  transition: 0.2s;
  background-color: #2c2c2c;
}

.estilo-card-campanha {
  // min-width: 225px !important;
  // max-width: 225px !important;
  // min-height: 305px !important;
  // max-height: 305px !important;
  color: white !important;
  transition: 0.2s;
  background-color: #2c2c2c;
}



//inicio
.dados-result {
  font-size: 20px;
  font-weight: 600;
}

.titulo-card-ini {
  font-size: 13px;
  font-weight: 500;
}

.titulo-campanha-operador {
  font-size: 12px;
}

.select__multi-value {
  background-color: #2c2c2c;
}